import React, { ComponentType, FunctionComponent, MouseEventHandler, Suspense } from 'react'
import _ from 'lodash'
import { reportError } from '@wix/thunderbolt-commons'
import { getStyle, Props } from './tpaWidgetNative'
import LazySentry from '../lazySentry'

type State = {
	hasError: boolean
}

const TpaWidgetNativeDeadComp = React.lazy(
	() => import('./tpaWidgetNativeDeadComp/tpaWidgetNativeDeadComp' /* webpackChunkName: "tpaWidgetNativeDeadComp" */)
)

class TpaWidgetNativeErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = { hasError: false }
	}

	componentDidCatch(error: Error) {
		reportError(error, LazySentry, this.props.sentryDsn)
	}

	componentWillUnmount() {
		this.props.host.unregisterFromComponentDidLayout()
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	render() {
		const ReactComponent = this.props.ReactComponent

		if (this.state.hasError || !ReactComponent || this.props.__VIEWER_INTERNAL?.failedInSsr) {
			return (
				<Suspense fallback={<div />}>
					<TpaWidgetNativeDeadComp {...this.props} />
				</Suspense>
			)
		}

		return <ReactComponent {...this.props} />
	}
}

export const createTpaWidgetNative: ({
	ReactComponent,
}: {
	ReactComponent?: ComponentType<any>
}) => FunctionComponent<Props> = ({ ReactComponent }) => {
	return (props) => (
		<div
			id={props.id}
			style={getStyle(props)}
			onMouseEnter={(props.onMouseEnter as MouseEventHandler) || _.noop}
			onMouseLeave={(props.onMouseLeave as MouseEventHandler) || _.noop}
		>
			<TpaWidgetNativeErrorBoundary {...props} ReactComponent={ReactComponent} />
		</div>
	)
}
