import { IComponentsRegistrar } from '@wix/thunderbolt-components-loader'
import { named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import {
	Experiments,
	ExperimentsSymbol,
	SiteFeatureConfigSymbol,
	ViewMode,
	ViewModeSym,
} from '@wix/thunderbolt-symbols'
import { name, OOISsrManagerSymbol, ReactLoaderForOOISymbol } from './symbols'
import type { OOIComponentData, OOIComponentLoader, OOISiteConfig, OOISsrManager } from './types'
import { reportError } from '@wix/thunderbolt-commons'
import LazySentry from './lazySentry'
import { createTpaWidgetNative } from './tpaWidgetNativeFactory'

export const FEED_WIDGET_ID_PROD = '14c1462a-97f2-9f6a-7bb7-f5541f23caa6'

export const ooiComponentsRegistrar = withDependencies(
	[
		named(SiteFeatureConfigSymbol, name),
		ReactLoaderForOOISymbol,
		ExperimentsSymbol,
		ViewModeSym,
		optional(OOISsrManagerSymbol),
	],
	(
		{ ooiComponentsData, blogMobileComponentUrl }: OOISiteConfig,
		loader: OOIComponentLoader,
		experiments: Experiments,
		viewMode: ViewMode,
		ooiSsrManager?: OOISsrManager
	): IComponentsRegistrar => {
		const loadComponent = ({ componentUrl, sentryDsn, widgetId }: OOIComponentData) => async () => {
			try {
				const shouldLoadBlogMobileComponentUrl =
					widgetId === FEED_WIDGET_ID_PROD &&
					experiments['specs.thunderbolt.blog_mobile_bundle_new'] &&
					viewMode === 'mobile'

				const url = shouldLoadBlogMobileComponentUrl ? blogMobileComponentUrl : componentUrl

				const ReactComponent = await loader.loadComponent(url)
				if (!ReactComponent) {
					reportError(new Error('component is not exported'), LazySentry, sentryDsn)
				}
				return { component: createTpaWidgetNative({ ReactComponent, ooiSsrManager }) }
			} catch (error) {
				reportError(error, LazySentry, sentryDsn)
				return { component: createTpaWidgetNative({ ooiSsrManager }) }
			}
		}
		return {
			registerComponents: (hostAPI) => {
				Object.entries(ooiComponentsData).forEach(([widgetId, { componentUrl, sentryDsn }]) => {
					hostAPI.registerComponent(
						'tpaWidgetNative',
						loadComponent({ componentUrl, sentryDsn, widgetId }),
						widgetId
					)
				})
			},
		}
	}
)
